const cost_tonne_per_km = {
    name: 'cost_tonne_per_km',
    title: 'Оплат за тонно-километр',
    url: '/settings/cost_tonne_per_km',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.cost_tonne_per_km.list',
        read: 'v1.cost_tonne_per_km.read',
        create: 'v1.cost_tonne_per_km.create',
        update: 'v1.cost_tonne_per_km.update',
        delete: 'v1.cost_tonne_per_km.delete',
    },
    listColumns: [
        {
            name: 'cost',
            required: true,
            label: 'Сумма',
            align: 'left',
            field: 'cost',
            sortable: true,
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
    editColumns: [
        {
            name: 'cost',
            required: true,
            label: 'Сумма',
            align: 'left',
            field: 'cost',
            sortable: true,
            type: 'number',
        },
        {
            name: 'start_date',
            required: true,
            label: 'Дата начала',
            align: 'left',
            field: 'start_date',
            sortable: true,
            type: 'date',
        },
        {
            name: 'end_date',
            required: true,
            label: 'Дата окончания',
            align: 'left',
            field: 'end_date',
            sortable: true,
            type: 'date',
        },
    ],
};

export {
    cost_tonne_per_km,
};
